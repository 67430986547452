<template>
  <div style="width: 100%">
    <el-carousel
      arrow="never"
      direction="horizontal"
      :height="bannerHeight + 'px'"
    >
      <el-carousel-item v-for="(item, i) in carouselImgList" :key="i">
        <el-image :src="item.url" class="swiperImg"></el-image>
        <div class="swiperContent">
          <!-- <div class="title">{{ item.title }}</div>
            <div class="mt30 mb30">
              <div class="rowFB">
                <div></div>
              </div>
            </div> -->
          <el-row :gutter="10">
            <el-col :span="12" align="right" class="mt50">
              <div class="title">{{ item.title }}</div>
              <div class="mt30 mb30">
                <div class="rowFB">
                  <div v-for="(item0, index) in urlList" :key="index">
                    <el-image :src="item0.url"></el-image>
                  </div>
                </div>
              </div>
              <div class="content">
                <div>{{ item.content }}</div>
                <div>{{ item.content1 }}</div>
              </div>
            </el-col>
            <el-col :span="12" align="left">
              <el-image :src="item.url1"></el-image>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="rightImg">
          <el-image :src="item.url1"></el-image>
        </div> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carouselImgList: [
        {
          // url: require('../../static/image/home/carousel1.png'),
          url:'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/carousel1.png',
          title: '玩住虎门店营销管理系统',
          content:
            '玩住虎致力于网咖、电竞酒店、餐饮行业，打造高效专业的营销管理平台，',
          content1:
            '有效解决门店运营中的痛点问题，构建全方位的生态系统，为门店赋能。',
          // content: '玩住虎致力于网咖、电竞酒店、餐饮行业，打造高效专业的营销管理平台，有效解决门店运营中的痛点问题，构建全方位的生态系统，为门店赋能；'
          url1: require('../../static/image/home/img1.png'),
        },
        // {
        //   url: require('../../static/image/home/carousel1.png'),
        // },
        // {
        //   url: require("../../assets/image/home/carouse2.png")
        // },
        // {
        //   url: require("../../assets/image/home/carouse3.png")
        // },
        // {
        //   url: require("../../assets/image/home/carouse4.png")
        // },
        // {
        //   url: require("../../assets/image/home/carouse5.png")
        // },
        // {
        //   url: require("../../assets/image/home/carouse6.png")
        // }
      ],
      urlList: [
        {
          url: require('../../static/image/home/label1.png'),
        },
        {
          url: require('../../static/image/home/label2.png'),
        },
        {
          url: require('../../static/image/home/label3.png'),
        },
      ],

      screenWidth: 0,
      bannerHeight: 0,
    }
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = (700 / 1920) * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = (700 / 1920) * this.screenWidth
    }
    console.log('bannerHeight', this.bannerHeight)
  },
}
</script>
<style lang="scss" scoped>
.swiperImg {
  position: relative;
}
.swiperContent {
  position: absolute;
  top: 30%;
  left: 18.8%;
  color: #fff;
  .title {
    font-size: 46px;
    font-weight: 800;
    font-family: PingFang SC-Heavy, PingFang SC;
    // letter-spacing: 20px;
    letter-spacing: 4px;
  }
  .content {
    font-size: 16px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
  }
}
.rightImg {
  position: absolute;
  top: 190px;
  right: 18.8%;
}
</style>
