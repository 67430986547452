<template>
  <div id="app" style="overflow:hidden">
    <Header></Header>
    <router-view/>
  </div>
</template>
<script>
import Header from '../src/views/Header.vue'

export default {
  data() {
    return {

    }
  },
  components: {Header}
}
</script>
<style lang="scss">
* {
  padding: 0px;
  margin: 0px;
}
// *::-webkit-scrollbar {
// 	display: none;
// }
// * {
// 	scrollbar-width: none;
// }
// * {
// 	-ms-overflow-style: none;
// }
</style>
