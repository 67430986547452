<template>
  <div>
    <div class="img">
      <div class="home9LayBg">
        <div>
          <span class="f32"> 立即享受15天免费试用体验 </span>
          <span class="title-border f16 ftb ml30">免费咨询</span>
        </div>
      </div>
      <div class="home9LayBg2">
        <div class="rowFB">
          <div>
            <el-image
              :src="require('../../static/image/home/logo.png')"
            ></el-image>
          </div>
          <div class="dec">
            玩住虎致力于网咖、电竞酒店、餐饮行业，打造高效专业的营销管理平台，有效解决门店运营中的痛点问题，
            帮助门店提高专业化运营水平，提升门店综合收益，
            构建全方位生态系统，为门店赋能。玩住虎由门店电脑POS端PMS、
            线上销售平台（公众号、微信及抖音小程序）、云平台（管理系统）、移动平台（玩住虎APP及小程序）、服务平
            台（三方支付、三方服务、智慧酒店系统）组成，具有界面简洁、功能全面、实时数据、图表展示、支持多终端、
            跨平台、移动应用、简单易用的特点。
          </div>
        </div>
        <div class="divider mt30"></div>
        <div class="rowFB mt20 mb30">
          <div class="mt30">
            <ul>
              <li>售前：18056025603</li>
              <li>客服：18056025603</li>
              <li>QQ: 5671218</li>
              <li>安徽省合肥市蜀山区黄山路赛博数码广场六栋2203室</li>
            </ul>
          </div>
          <div class="rowFB align-c">
            <div>
              <el-image
                style="display: inline-block"
                :src="require('../../static/image/home/manage.png')"
              ></el-image>
              <div class="mt15">管理端小程序</div>
            </div>
            <div class="ml30 mr30 mb20">
              <el-image
                :src="require('../../static/image/home/member.png')"
              ></el-image>
              <div class="mt15">会员端小程序</div>
            </div>
            <div>
              <el-image
                :src="require('../../static/image/home/kefu.png')"
              ></el-image>
              <div class="mt15">客服咨询</div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="align-c mt30">
          <div class="mb10">
            <a href="https://beian.miit.gov.cn/">皖ICP备19006361号-2</a>
          </div>
          <div>CopyRight © 2022 安徽七宝树软件有限公司 版权所有</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.img {
  position: relative;
}
.home8Bg {
  height: 650px;
}
.home9LayBg {
  // position: relative;
  width: 100%;
  height: 140px;
  // background: linear-gradient(87deg, #3b69fb 0%, #13d0c7 100%);
  background: url('../../static//image/home/footetNav.png');
  // text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-border {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 30px;
}
.home9LayBg2 {
  margin: 110px 355px 48px 355px;
  font-size: 13px;
  ul {
    list-style: none;
  }
}
.dec {
  width: 60%;
  line-height: 30px;
}
</style>
