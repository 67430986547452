<template>
  <div class="home" style="width: 100%">
    <!-- <div>
      <el-carousel arrow="never"  direction="horizontal" :height="bannerHeight+'px'">
        <el-carousel-item v-for="(item, index) in carouselList" :key="index">
          <el-image :src="item.url"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <Carousel></Carousel>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/homeBg1.png')"></el-image> -->
      <el-image :src="url1"></el-image>
      <div class="home1Title">
        <div class="title">门店收银POS端</div>
        <div class="subTitle">提供一站式功能服务，助力门店便捷管理</div>
      </div>
      <div class="home1Img">
        <el-image :src="require('../../static/image/home/home1.png')"></el-image>
      </div>
    </div>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/homeBg2.png')"></el-image> -->
      <el-image :src="url2"></el-image>
      <div class="home1Title">
        <div class="title fwhite">门店客户端</div>
        <div class="subTitle fwhite">提供一站式功能服务，助力门店便捷管理</div>
      </div>
      <div class="home2Img">
        <ul>
          <li v-for="(item, index) in iconList"
              :key="index"
              class="mb20">
            <div class="rowFS">
              <div>
                <el-image :src="item.url"></el-image>
              </div>
              <div class="ml10">
                <div class="f16">{{ item.text1 }}</div>
                <div class="subText">{{ item.text2 }}</div>
              </div>
            </div>
          </li>
        </ul>
        <div class="homg2Img2">
          <el-image :src="require('../../static/image/home/gift.png')"></el-image>
        </div>
      </div>
    </div>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/homeBg3.png')"></el-image> -->
      <el-image :src="url3"></el-image>
      <div class="home1Title">
        <div class="title">会员端小程序</div>
        <div class="subTitle">
          专属于自己的门店会员小程序，自由会员系统、精选商品活动、在线管家服务，各项服务等你解锁
        </div>
      </div>
    </div>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/homeBg4.png')"></el-image> -->
      <el-image :src="url4"></el-image>
      <div class="home1Title">
        <div class="title">管理端小程序</div>
        <div class="subTitle">移动后台，功能应有尽有</div>
      </div>
      <div class="home4Img">
        <ul>
          <li v-for="(item, index) in iconList4"
              :key="index"
              class="mb20">
            <div class="rowFS">
              <div>
                <el-image :src="item.url"></el-image>
              </div>
              <div class="ml10">
                <div class="f16">{{ item.text1 }}</div>
                <div class="subText">{{ item.text2 }}</div>
              </div>
            </div>
          </li>
        </ul>
        <!-- <div class="homg2Img2">
          <el-image :src="require('../../static/image/home/gift.png')"></el-image>
        </div> -->
      </div>
    </div>
    <div class="img">
      <!-- <el-image :src="require('../../static/image/home/homeBg5.png')"></el-image> -->
      <el-image :src="url5"></el-image>
      <div class="home1Title">
        <div class="title fwhite">门店管理 只需要一个玩住虎</div>
        <div class="subTitle fwhite">
          有效解决门店运营中的痛点问题，构建全方位的生态系统。
        </div>
      </div>
      <div class="home5Img">
        <div class="home5Left">
          <div class="home5ImgTitle mb20">协同办公</div>
          <ul class="rowFAW">
            <li v-for="(item, index) in iconList5"
                :key="index"
                class="mb20 iconImg">
              <div>
                <div>
                  <el-image :src="item.url"></el-image>
                </div>
                <div class="mt10">{{ item.text1 }}</div>
              </div>
            </li>
          </ul>
          <!-- <el-row :gutter="10">
            <el-col :span="8" v-for="(item, index) in iconList5" :key="index" class="mb20 iconImg">
              <div>
                <div>
                  <el-image :src="item.url"></el-image>
                </div>
                <div class="mt10">{{ item.text1 }}</div>
              </div>
            </el-col>
          </el-row> -->
        </div>
        <div>
          <div class="home5Left ml20">
            <div class="home5ImgTitle mb20">会员营销</div>
            <ul class="rowFA">
              <li v-for="(item, index) in iconList5Right1"
                  :key="index"
                  class="mb20 iconImg">
                <div>
                  <div>
                    <el-image :src="item.url"></el-image>
                  </div>
                  <div class="mt10">{{ item.text1 }}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="home5Left ml20 mt20">
            <div class="home5ImgTitle mb20">财务报表</div>
            <ul class="rowFA">
              <li v-for="(item, index) in iconList5Right2"
                  :key="index"
                  class="mb20 iconImg">
                <div>
                  <div>
                    <el-image :src="item.url"></el-image>
                  </div>
                  <div class="mt10">{{ item.text1 }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="img">
      <el-image :src="url6"></el-image>
      <div class="home6Title">
        <div>
          <div class="title fwhite">电子价格牌</div>
          <div class="subTitle fwhite">
            展示信息丰富、自定义展示模板、提升门店形象（支持多种皮肤模板更换）
          </div>
        </div>
        <div class="mt50 rowFA">
          <div class="home6Layout mr20"
              v-for="(item, index) in home6List"
              :key="index">
            <div class="title"
                :class="
                index === 0
                  ? 'titlebg1'
                  : index === 1
                  ? 'titlebg2'
                  : index === 2
                  ? 'titlebg3'
                  : 'titlebg1'
              ">
              {{ item.title }}
            </div>
            <ul class="">
              <li class="mb10 colorF27"
                  v-for="(item0, index0) in item.list"
                  :key="index0">
                {{ item0 }}
              </li>
            </ul>
            <div class="homg6ImgLay">
              <el-image :src="item.url"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img">
      <el-image :src="require('../../static/image/home/homeBg7.png')"></el-image>
      <div class="home1Title">
        <div class="title">系统后台</div>
        <div class="subTitle">安全稳定的系统，为商家提供支持</div>
      </div>
    </div>
    <div class="img home8Bg">
      <div class="home1Title">
        <div class="title">选择我们的门店</div>
      </div>
      <div>
        <el-image :src="require('../../static/image/home/homeCustoms.png')"></el-image>
      </div>
      <!-- <div class="pic">
        <div class="animation1">
          <el-image :src="require('../../static/image/home/panda.png')"></el-image>
        </div>
      </div> -->
    </div>
    <Footer></Footer>
    <!-- <div class="img">
      <div class="home9LayBg">
        <div>
          <span class="f32"> 立即享受15天免费试用体验 </span>
          <span class="title-border f16 ftb ml30">免费咨询</span>
        </div>
      </div>
      <div class="home9LayBg2">
        <div class="rowFB">
          <div>
            <el-image
              :src="require('../../static/image/home/logo.png')"
            ></el-image>
          </div>
          <div class="dec">
            玩住虎致力于网咖、电竞酒店、餐饮行业，打造高效专业的营销管理平台，有效解决门店运营中的痛点问题，
            帮助门店提高专业化运营水平，提升门店综合收益，
            构建全方位生态系统，为门店赋能。玩住虎由门店电脑POS端PMS、
            线上销售平台（公众号、微信及抖音小程序）、云平台（管理系统）、移动平台（玩住虎APP及小程序）、服务平
            台（三方支付、三方服务、智慧酒店系统）组成，具有界面简洁、功能全面、实时数据、图表展示、支持多终端、
            跨平台、移动应用、简单易用的特点。
          </div>
        </div>
        <div class="divider mt30"></div>
        <div class="rowFB mt20">
          <div class="mt30">
            <ul>
              <li>售前：18056025606</li>
              <li>客服：18056025606</li>
              <li>QQ: 75696652</li>
              <li>安徽省合肥市蜀山区黄山路赛博数码广场六栋2203室</li>
            </ul>
          </div>
          <div class="rowFB align-c">
            <div>
              <el-image
                :src="require('../../static/image/home/manage.png')"
              ></el-image>
              <span class="mt15">管理端小程序</span>
            </div>
            <div class="ml30 mr30 mb20">
              <el-image
                :src="require('../../static/image/home/member.png')"
              ></el-image>
              <span class="mt15">会员端小程序</span>
            </div>
            <div>
              <el-image
                :src="require('../../static/image/home/kefu.png')"
              ></el-image>
              <span class="mt15">客服咨询</span>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="align-c mt30">
          <div class="mb10">
            皖ICP备19006361号-1 皖公网安备 51019002004929号
          </div>
          <div>CopyRight © 2022 安徽七宝树软件有限公司 版权所有</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
    Footer,
  },
  data () {
    return {
      iconList: [
        {
          url: require('../../static/image/home/home2Icon1.png'),
          text1: '呼叫服务',
          text2: '需要服务可随时呼叫',
        },
        {
          url: require('../../static/image/home/home2Icon2.png'),
          text1: '充值中心',
          text2: '可充值会员余额',
        },
        {
          url: require('../../static/image/home/home2Icon3.png'),
          text1: '商品点购',
          text2: '客户可自行点购商品',
        },
        {
          url: require('../../static/image/home/home2Icon4.png'),
          text1: '自助续房',
          text2: '门店自由会员系统，等级越高优惠力度越大，权益越多',
        },
        {
          url: require('../../static/image/home/home2Icon5.png'),
          text1: '活动中心',
          text2: '各类门店活动，回馈客户',
        },
        {
          url: require('../../static/image/home/home2Icon6.png'),
          text1: '积分中心',
          text2: '会员积分可兑换商品，也可以做任务获得积分',
        },
      ],
      iconList4: [
        {
          url: require('../../static/image/home/home4Icon1.png'),
          text1: '数据报表',
          text2: '实时查看营业收入情况，月度年度数据统计，快捷查看门店数据报表',
        },
        {
          url: require('../../static/image/home/home4Icon2.png'),
          text1: '掌上房态',
          text2:
            '智能查询客房状态，开房/预定/续住/换房/联房/远期房态等一系列快捷操作',
        },
        {
          url: require('../../static/image/home/home4Icon3.png'),
          text1: '移动智能办公',
          text2: '便捷管理团队，提供办公效率',
        },
        {
          url: require('../../static/image/home/home4Icon4.png'),
          text1: '响应客需',
          text2:
            '及时响应客户需求，呼叫服务，打扫服务，点购商品，是一个移动的POS端',
        },
        {
          url: require('../../static/image/home/home4Icon5.png'),
          text1: '定制模板',
          text2: '提供多个模板选择，随心所欲',
        },
      ],
      iconList5: [
        {
          url: require('../../static/image/home/home5Icon1.png'),
          text1: '门店管理',
        },
        {
          url: require('../../static/image/home/home5Icon2.png'),
          text1: '办公管理',
        },
        {
          url: require('../../static/image/home/home5Icon3.png'),
          text1: '商品管理',
        },
        {
          url: require('../../static/image/home/home5Icon4.png'),
          text1: '仓库管理',
        },
        {
          url: require('../../static/image/home/home5Icon5.png'),
          text1: '会员管理',
        },
        {
          url: require('../../static/image/home/home5Icon6.png'),
          text1: '酒店管理',
        },
        {
          url: require('../../static/image/home/home5Icon7.png'),
          text1: '网咖管理',
        },
        {
          url: require('../../static/image/home/home5Icon8.png'),
          text1: '餐饮管理',
        },
        {
          url: require('../../static/image/home/home5Icon9.png'),
          text1: '小程序',
        },
      ],
      iconList5Right1: [
        {
          url: require('../../static/image/home/home5Icon10.png'),
          text1: '门店活动',
        },
        {
          url: require('../../static/image/home/home5Icon11.png'),
          text1: '卡券管理',
        },
        {
          url: require('../../static/image/home/home5Icon12.png'),
          text1: '积分商城',
        },
      ],
      iconList5Right2: [
        {
          url: require('../../static/image/home/home5Icon13.png'),
          text1: '财务管理',
        },
        {
          url: require('../../static/image/home/home5Icon14.png'),
          text1: '报表管理',
        },
        {
          url: require('../../static/image/home/home5Icon15.png'),
          text1: '绩效管理',
        },
      ],
      home6List: [
        {
          title: '电子房价牌',
          list: [
            '房间实景图片轮播，预订电话、会员权益展示信息显示',
            '充值入住等活动信息显示',
            '房型门市价格、会员价格、小程序价格、剩余房量显示',
            '订房小程序二维码展示，可扫码预定',
          ],
          url: require('../../static/image/home/home6Img1.png'),
          // url: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/home6Img1.png',
        },
        {
          title: '上网价目表',
          list: [
            '门店区域临时用户、会员价格显示',
            '门店区域剩余机器数量显示，客户可直观了解',
            '门店公告显示',
          ],
          url: require('../../static/image/home/home6Img2.png'),
          // url: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/home6Img2.png',
        },
        {
          title: '饮品价目表',
          list: [
            '不同系列饮品价格显示',
            '饮品名字后面冷热口味显示，客户可直观了解',
            '饮片属性显示',
          ],
          url: require('../../static/image/home/home6Img3.png'),
          // url: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/home6Img3.png',
        },
      ],
      // screenWidth: 0,
      // bannerHeight: 0,
      url1: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg1.png',
      url2: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg2.png',
      url3: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg3.png',
      url4: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg4.png',
      url5: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg5.png',
      url6: 'https://wanzhuhu-1305683220.cos.ap-shanghai.myqcloud.com/website/homeBg6.png',
    }
  },
  mounted () {
    // // 首次加载时,初始化高度
    // this.screenWidth = window.innerWidth
    // this.bannerHeight = 700 / 1920 * this.screenWidth
    // // 窗口大小发生改变
    // window.onresize = () => {
    //   this.screenWidth = window.innerWidth
    //   this.bannerHeight = 700 / 1920 * this.screenWidth
    // }
    // console.log('bannerHeight', this.bannerHeight);
  },
}
</script>

<style lang="scss" scoped>
// .img .el-image {
//   display: block;
// }
.home {
  width: 100%;
}

.img {
  position: relative;
  // transform: translate(-50%, 0%);
  .el-image {
    // display: block;
    display: flex;
  }
  .home1Title {
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
  .title {
    color: #121f27;
    font-size: 36px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    line-height: 42px;
    margin-bottom: 15px;
  }
  .subTitle {
    color: #2b2f36;
  }
  .fwhite {
    color: #fff;
  }
  // .home2Title {
  //   left: 48%;
  // }
  // .home3Title {
  //   left: 33%;
  // }
  // .home4Title {
  //   left: 46%;
  // }
  // .home5Title {
  //   left: 40%;
  // }
  .home6Title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;

    .homg6ImgLay {
      position: fixed;
      bottom: 5%;
    }
  }
  // .home7Title {
  //   position: absolute;
  //   left:50%;
  //   top:15%;
  //   transform: translateX(-50%) translateY(-50%);
  //   text-align: center;
  // }
  // .home8Title {
  //   position: absolute;
  //   left:50%;
  //   top:15%;
  //   transform: translateX(-50%) translateY(-50%);
  //   text-align: center;
  // }
}
.home1Img {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.home2Img {
  position: absolute;
  top: 280px;
  left: 20%;
  li {
    list-style: none;
  }
  .subText {
    color: #121f27;
    line-height: 32px;
    font-size: 13px;
  }
}
.homg2Img2 {
  position: absolute;
  top: 30px;
  right: -10%;
}
.home4Img {
  position: absolute;
  top: 240px;
  right: 14%;
  width: 568px;
  // height: 476px;
  background: #ffffff;
  border-radius: 20px;
  padding: 50px 36px;
  opacity: 1;
  li {
    list-style: none;
  }
  .subText {
    color: #121f27;
    line-height: 32px;
    font-size: 13px;
  }
}
.home5Img {
  position: absolute;
  top: 240px;
  left: 25%;
  display: flex;
  justify-content: flex-start;
  li {
    list-style: none;
  }
  .subText {
    color: #121f27;
    line-height: 32px;
    font-size: 13px;
  }
  .home5Left {
    width: 450px;
    // height: 476px;
    background: #ffffff;
    border-radius: 20px;
    padding: 31px 0px 31px 47px;
    opacity: 1;
  }
}
.home5ImgTitle {
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #2b2f36;
}
.iconImg {
  width: 80px;
  margin-left: 0px;
  margin-right: 70px;
}
.home6Layout {
  // width: 382px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 1px rgba(48, 49, 51, 0.1);
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  padding: 28px;
  box-sizing: border-box;

  .titlebg1 {
    background: linear-gradient(270deg, #45df85 0%, #10c55b 100%);
  }
  .titlebg2 {
    background: linear-gradient(91deg, #007aec 0%, #3899f4 100%);
  }
  .titlebg3 {
    background: linear-gradient(90deg, #3b67f2 0%, #5177f1 100%);
  }

  .title {
    border-radius: 16px;
    font-size: 24px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
    width: 325px;
    margin-bottom: 20px;
  }
  ul {
    color: #c4c7cc;
    list-style-position: inside;
    font-size: 13px;
    li {
      color: #121f27;
    }
  }
}
.home8Bg {
  height: 650px;
}
.home9LayBg {
  // position: relative;
  width: 100%;
  height: 140px;
  background: linear-gradient(87deg, #3b69fb 0%, #13d0c7 100%);
  // text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-border {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 30px;
}
.home9LayBg2 {
  margin: 110px 355px 48px 355px;
  font-size: 13px;
  ul {
    list-style: none;
  }
}
.dec {
  width: 60%;
  line-height: 30px;
}
.pic {
  position: relative;
  .el-image {
    width: 120px;
  }

  .animation1 {
    position: absolute;
    top: 10%;
    left: 20%;
    // animation: animation1 1s steps(5) infinite;
    transform: translate(-1%, 0);
  }
}
@keyframes animation1 {
  0% {
    transform: translateX(-1px);
  }
  5% {
    transform: translateX(-5px);
  }
  10% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(-50px);
  }
  60% {
    transform: translateX(-60px);
  }
  70% {
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(-100px);
  }
}
</style>
