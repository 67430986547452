<template>
  <div style="text-align: center">
    <div class="container">
      <div class="headerBg">
        <div class="mb10 mt10">
          <el-image
            :src="require('../../static/image/headerLogo2.png')"
          ></el-image>
        </div>
      </div>
      <ul class="nav">
        <li v-for="(item, index) in navList" :key="index" @click="changeNav(item)">
          <a href="javascript:;" :class="item.actived ? 'activeA':''">
            <router-link v-show="item.id !== 6 && item.id !== 7 && item.id !== 8" :to="item.path">{{ item.name }}</router-link>
            <a v-show="item.id === 6 || item.id === 7 || item.id === 8" :href="item.url" target="_blank">{{ item.name }}</a>
          </a>
        </li>
        <!-- <li>
          <a href="javascript:;">
            <router-link to="/">首页</router-link>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/hotel">酒店系统</router-link>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/netBar">网咖系统</router-link>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/updateLog">更新日志</router-link>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/download">下载中心</router-link>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/customerCase">客户案例</router-link>
          </a>
        </li>
        <li>
          <a href="http://eshotel.wanzhuhu.com/login" target="_blank">后台登录</a>
        </li>
        <li>
          <a href="javascript:;">
            <router-link to="/help">帮助中心</router-link>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          id: 0,
          path: '/',
          name: '首页',
          url: '',
          actived: true,
        },
        {
          id: 1,
          path: '/hotel',
          name: '酒店系统',
          url: '',
          actived: false,
        },
        {
          id: 2,
          path: '/netBar',
          name: '网咖系统',
          url: '',
          actived: false,
        },
        {
          id: 3,
          path: '/updateLog',
          name: '更新日志',
          url: '',
          actived: false,
        },
        {
          id: 4,
          path: '/download',
          name: '下载中心',
          url: '',
          actived: false,
        },
        {
          id: 5,
          path: '/customerCase',
          name: '客户案例',
          url: '',
          actived: false,
        },
        {
          id: 6,
          path: '',
          name: '后台登录',
          url: 'http://eshotel.wanzhuhu.com/login',
          actived: false,
        },
        {
          id: 8, 
          path: '',
          name: '新版后台登录',
          url: 'http://chain.wanzhuhu.com/admin/do/phonelogin',
          actived: false,
        },
        {
          id: 7, 
          path: '',
          name: '帮助中心',
          url: 'https://www.yuque.com/ibven0/ngd5zk',
          actived: false,
        },
      ],
    }
  },
  created(){
    console.log('this.$route.query.XXX（XXX代表你要取的参数名称）', this.$route.query)
    // this.$route.query.id = 5
    if (this.$route.query.id !== undefined) {
      this.navList.forEach(item => {
        if (item.id === this.$route.query.id) {
          item.actived = true
        } else {
          item.actived = false
        }
      })
    }
  },
  watch: {
    $route(to , from) {
      console.log('监听路由', to , from);
      if (to.query.id !=='') {
        this.navList.forEach(item => {
          if (item.id == to.query.id) {
          // if (item.id == to.params.id) { // 刷新参数丢失
            item.actived = true
          } else {
            item.actived = false
          }
        })
      }
    }
  },
  methods: {
    changeNav(e) {
      for(let i in this.navList) {
        if (e.id === this.navList[i].id) {
          this.navList[i].actived = true
        } else {
          this.navList[i].actived = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 100%;
  // background-color: #000;
  // opacity: 0.25;
  background-color: rgba(0, 0, 0, 0.25);
  height: 130px;
  position: fixed;
  // top: 0px;
  // z-index: 9999;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
}
// .headerBg {
//   border-bottom: 1px solid #fff;
// }
.nav {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  // border-top: 1px solid #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  // ul {

  // }
  li {
    list-style: none;
    padding: 0 37px;

    a {
      text-decoration: none;
      color: #fff;
      padding-bottom: 5px;
    }
  }
  .activeA{
    a {
      border-bottom: 2px solid #10c55b;
    }
  }
}
a:hover {
  border-bottom: 2px solid #10c55b;
}


</style>
